import { default as cafe_45barOWnAw1kXG8Meta } from "/opt/build/repo/pages/cafe-bar.vue?macro=true";
import { default as festivals7ZgTTdKYYzMeta } from "/opt/build/repo/pages/festivals.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_938ZmxOSdLoBMeta } from "/opt/build/repo/pages/leersysteem/[...slug].vue?macro=true";
import { default as online_45trainingDFXacxfDiyMeta } from "/opt/build/repo/pages/online-training.vue?macro=true";
import { default as prijzenxKTVpo6qLOMeta } from "/opt/build/repo/pages/prijzen.vue?macro=true";
import { default as restaurantsj9OZkTNvafMeta } from "/opt/build/repo/pages/restaurants.vue?macro=true";
import { default as slijterijkXJDGysaKBMeta } from "/opt/build/repo/pages/slijterij.vue?macro=true";
import { default as testsQ0rJvZrjqMeta } from "/opt/build/repo/pages/test.vue?macro=true";
import { default as verenigingd2464OKD35Meta } from "/opt/build/repo/pages/vereniging.vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubVlEI75ehPB } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: cafe_45barOWnAw1kXG8Meta?.name ?? "cafe-bar",
    path: cafe_45barOWnAw1kXG8Meta?.path ?? "/cafe-bar",
    meta: cafe_45barOWnAw1kXG8Meta || {},
    alias: cafe_45barOWnAw1kXG8Meta?.alias || [],
    redirect: cafe_45barOWnAw1kXG8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/cafe-bar.vue").then(m => m.default || m)
  },
  {
    name: festivals7ZgTTdKYYzMeta?.name ?? "festivals",
    path: festivals7ZgTTdKYYzMeta?.path ?? "/festivals",
    meta: festivals7ZgTTdKYYzMeta || {},
    alias: festivals7ZgTTdKYYzMeta?.alias || [],
    redirect: festivals7ZgTTdKYYzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/festivals.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938ZmxOSdLoBMeta?.name ?? "leersysteem-slug",
    path: _91_46_46_46slug_938ZmxOSdLoBMeta?.path ?? "/leersysteem/:slug(.*)*",
    meta: _91_46_46_46slug_938ZmxOSdLoBMeta || {},
    alias: _91_46_46_46slug_938ZmxOSdLoBMeta?.alias || [],
    redirect: _91_46_46_46slug_938ZmxOSdLoBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/leersysteem/[...slug].vue").then(m => m.default || m)
  },
  {
    name: online_45trainingDFXacxfDiyMeta?.name ?? "online-training",
    path: online_45trainingDFXacxfDiyMeta?.path ?? "/online-training",
    meta: online_45trainingDFXacxfDiyMeta || {},
    alias: online_45trainingDFXacxfDiyMeta?.alias || [],
    redirect: online_45trainingDFXacxfDiyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/online-training.vue").then(m => m.default || m)
  },
  {
    name: prijzenxKTVpo6qLOMeta?.name ?? "prijzen",
    path: prijzenxKTVpo6qLOMeta?.path ?? "/prijzen",
    meta: prijzenxKTVpo6qLOMeta || {},
    alias: prijzenxKTVpo6qLOMeta?.alias || [],
    redirect: prijzenxKTVpo6qLOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/prijzen.vue").then(m => m.default || m)
  },
  {
    name: restaurantsj9OZkTNvafMeta?.name ?? "restaurants",
    path: restaurantsj9OZkTNvafMeta?.path ?? "/restaurants",
    meta: restaurantsj9OZkTNvafMeta || {},
    alias: restaurantsj9OZkTNvafMeta?.alias || [],
    redirect: restaurantsj9OZkTNvafMeta?.redirect,
    component: () => import("/opt/build/repo/pages/restaurants.vue").then(m => m.default || m)
  },
  {
    name: slijterijkXJDGysaKBMeta?.name ?? "slijterij",
    path: slijterijkXJDGysaKBMeta?.path ?? "/slijterij",
    meta: slijterijkXJDGysaKBMeta || {},
    alias: slijterijkXJDGysaKBMeta?.alias || [],
    redirect: slijterijkXJDGysaKBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/slijterij.vue").then(m => m.default || m)
  },
  {
    name: testsQ0rJvZrjqMeta?.name ?? "test",
    path: testsQ0rJvZrjqMeta?.path ?? "/test",
    meta: testsQ0rJvZrjqMeta || {},
    alias: testsQ0rJvZrjqMeta?.alias || [],
    redirect: testsQ0rJvZrjqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/test.vue").then(m => m.default || m)
  },
  {
    name: verenigingd2464OKD35Meta?.name ?? "vereniging",
    path: verenigingd2464OKD35Meta?.path ?? "/vereniging",
    meta: verenigingd2464OKD35Meta || {},
    alias: verenigingd2464OKD35Meta?.alias || [],
    redirect: verenigingd2464OKD35Meta?.redirect,
    component: () => import("/opt/build/repo/pages/vereniging.vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/docs",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  }
]